*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

header{
  background-color: #343A40;
  padding: 10px 100px 10px 100px;
  display: flex;
  justify-content: space-between;
}

header img{
  width: 120px;
  height: 50px;
}

header .btnLingua{
  display: flex;
  align-items: center;
}

header .btnLingua #btnLingua{
  background-color: white;
  border: none;
  color: black;
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 15px;
  font-weight: 600;
  cursor: pointer;
}

.title{
  background-color: rgb(226, 225, 225);
  width: 80%;
  margin: 30px auto 0 auto;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

.title h1{
  text-align: center;
  margin: 0 20px;
  font-size: 40px;
}

.title p{
  text-align: center;
  margin: 20px 30px 0 30px;
  font-size: 25px;
}

.in{
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.in input[type=text]{
  border: 1px solid #ccc;
  width: 100%;
  margin: 0 10%;
  border-radius: 10px;
  font-size: 30px;
  padding: 5px 10px;
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
  text-align: center;
}

.in h5{
  text-align: center;
  margin: 30px auto;
  display: block;
  font-size: 30px;
  background-color: #8312FA;
  padding: 10px 15px;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.box{
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 5px;
  padding-bottom: 20px;
}

.box img{
  width: 100%;
  border-radius: 5px;
}

.box a{
  background-color: #37976A;
  padding: 10px 15px;
  font-size: 25px;
  margin-top: 20px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.box .boxErro h2{
  background-color: red;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}

@media screen and (max-width: 1000px) {
  .title{
    width: 90%;
  }
  .title h1{
    font-size: 30px;
  }
  .in input[type=text]{
    font-size: 25px;
    margin: 0 5%;
  }
  .box{
    width: 90%;
  }
}

@media screen and (max-width: 550px){
  header{
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
  }
  header .btnLingua #btnLingua{
    font-size: 13px;
    margin-left: 10px;
  }
  .title h1{
    font-size: 20px;
    margin: 0 10px;
  }
  .title p{
    font-size: 16px;
    margin: 20px 10px 0 10px;
  }
  .in input[type=text]{
    font-size: 16px;
  }
  .in h5{
    font-size: 20px;
  }
}